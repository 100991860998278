import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';

export const availableBorderRadii = ['sm', 'md', 'lg', 'full'] as const;

type PliSkeletonBorderRadius = (typeof availableBorderRadii)[number];
export interface PliSkeletonProps {
    height: number;
    aspectRatio?: string;
    borderRadius?: PliSkeletonBorderRadius;
}

@customElement('pli-skeleton')
class PliSkeleton extends LitElement implements PliSkeletonProps {
    @property({ type: Number })
    height: number = 32;

    @property()
    aspectRatio?: string;

    @property()
    borderRadius?: PliSkeletonBorderRadius = 'md';

    static styles = [
        baseStyles,
        css`
            :host {
                --aspect-ratio: initial;
                --height: 32px;
                --border-radius: var(--radius-md);
            }
            @keyframes pulse {
                from,
                to {
                    opacity: 0.5;
                }

                50% {
                    opacity: 1;
                }
            }
            .pli-skeleton {
                width: 100%;
                height: var(--height);
                background-color: var(--skeleton-background);
                aspect-ratio: var(--aspect-ratio);
                border-radius: var(--border-radius);
                animation: pulse infinite 2500ms forwards;
            }
        `,
    ];

    render() {
        const { height, aspectRatio, borderRadius } = this;
        this.style.setProperty('--height', (aspectRatio && 'auto') || `${height}px`);
        this.style.setProperty('--aspect-ratio', aspectRatio ?? 'auto');
        this.style.setProperty('--border-radius', `var(--radius-${borderRadius}`);

        return html` <div class="pli-skeleton"></div> `;
    }
}
