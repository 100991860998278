import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './pli-text';
import { styles } from './styles';

type PliRadioItemProp = {
    text: string;
    value: string;
};

@customElement('pli-radio-item-group')
export class PliRadioItemGroup extends LitElement {
    @property({ type: Array })
    items: PliRadioItemProp[];

    @property()
    selected: string;

    @state()
    _selected: string = null;

    connectedCallback() {
        super.connectedCallback();
        this._selected = this.selected ?? null;
    }

    static styles = [
        styles.grid,
        styles.flex,
        css`
            :host {
                --border-color: var(--button-background-primary);
                --active-shadow: inset 0 0 0 var(--size-0-25) var(--border-color), var(--input-border-focus-shadow);
                --default-shadow: inset 0 0 0 var(--size-0-25) var(--border-color);
                --outer-shadow: 0 0 0 var(--radius-0-25) rgba(0, 0, 0, 0.5);
            }

            .radio-item-group {
                padding: var(--size-1);
                background-color: var(--radio-group-background);
                border-radius: var(--radius-sm);
            }

            label {
                position: relative;
            }

            label:has(input:focus) .custom-radio-icon {
                box-shadow: var(--active-shadow);
            }

            input[type='radio'] {
                position: absolute;
                opacity: 0;
            }

            .custom-radio-icon {
                width: var(--size-1);
                height: var(--size-1);
                border-radius: 50%;
                border: var(--border-default);
            }

            .custom-radio-icon.active {
                box-shadow: inset 0 0 0 var(--size-0-25) var(--border-color); /* Inset shadow to simulate border */
                border-color: var(--border-color);
            }
        `,
    ];

    handleChange(index: number) {
        this._selected = this.items[index].value;
        const _event: PliRadioItemGroupUpdatedEvent = new CustomEvent('change', {
            composed: true,
            detail: { selected: this._selected },
        });
        this.dispatchEvent(_event);
    }

    isRadioItemActive(value: PliRadioItemProp['value']) {
        return value === this._selected;
    }

    render() {
        return html`<div class="radio-item-group grid-vertical gap-1">
            ${this.items.map(
                (i, index) =>
                    html`<label for="${i.value}" class="flex items-center gap-05">
                        <div class="custom-radio-icon ${this.isRadioItemActive(i.value) ? 'active' : null}"></div>
                        <input
                            type="radio"
                            @change="${() => this.handleChange(index)}"
                            .checked="${this.isRadioItemActive(i.value)}"
                            value="${i.value}"
                            name="a"
                            id="${i.value}"
                        /><pli-text>${i.text}</pli-text></label
                    >`,
            )}
        </div>`;
    }
}

export type PliRadioItemGroupUpdatedEvent = CustomEvent<{ selected: string }>;
