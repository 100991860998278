import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './styles';
import './pli-card';
import './pli-icon';
import './pli-text';
import './pli-progress-bar';
import { when } from 'lit/directives/when.js';
import './pli-button';
import { classMap } from 'lit/directives/class-map.js';

export type PliToastProps = {
    title?: string;
    bodyText?: string;
    lifespan?: number;
};

@customElement('pli-toast')
class PliToast extends LitElement {
    static styles = [
        styles.flex,
        styles.grid,
        styles.padding,
        css`
            @keyframes slide-in {
                from {
                    transform: translateX(100%);
                }
                to {
                    transform: translateX(0);
                }
            }
            @keyframes count-down {
                from {
                    width: 100%;
                }
                to {
                    width: 0;
                }
            }

            :host {
                animation: slide-in 500ms ease forwards;
                animation-delay: var(--delay);
            }

            .timer {
                background-color: var(--color-teal-200);
                height: var(--size-0-25);
                width: 100%;
            }

            .timer-inner {
                background-color: var(--color-teal-500);
                height: 100%;
                width: 100%;
                animation: count-down var(--animation-duration) linear forwards;
            }

            .toast {
                background-color: var(--color-teal-100);
                color: var(--color-teal-950);
                border-radius: var(--radius-3);
                overflow: hidden;
            }
        `,
    ];

    @property()
    title: PliToastProps['title'];

    @property()
    bodyText: PliToastProps['bodyText'];

    @property({ type: Number })
    lifespan?: PliToastProps['lifespan'];

    handleClick() {
        this.dispatchEvent(
            new CustomEvent('remove', {
                composed: true,
            }),
        );
    }

    connectedCallback() {
        super.connectedCallback();
        if (!this.lifespan) {
            return;
        }

        setTimeout(() => {
            this.dispatchEvent(
                new CustomEvent('timer-finished', {
                    composed: true,
                }),
            );
        }, this.lifespan);
    }

    render() {
        if (this.lifespan !== undefined) {
            this.style.setProperty('--animation-duration', `${this.lifespan}ms`);
        }

        const outerWrapperClasses = classMap({
            'flex justify-between pl-075': true,
            'items-center': !this.title,
            'items-start': Boolean(this.title) && Boolean(this.bodyText),
        });

        const flexClasses = classMap({
            'flex gap-05': true,
            'items-center': !this.title,
            'py-075': Boolean(this.title) && Boolean(this.bodyText),
        });

        return html`
            <div class="grid">
                <div class="toast col-span-3 col-start-10 col-lg-span-2 col-lg-start-11">
                    <div class="${outerWrapperClasses}">
                        <div class="${flexClasses}">
                            <pli-icon name="check-circle-fill" size="${20}"></pli-icon>
                            <div class="grid-vertical gap-05">
                                ${when(
                                    Boolean(this.title),
                                    () => html`<pli-text><strong>${this.title}</strong></pli-text>`,
                                )}
                                ${when(this.bodyText, () => html`<pli-text>${this.bodyText}</pli-text>`)}
                            </div>
                        </div>
                        <pli-button iconEnd="x" variant="text" .onClick="${() => this.handleClick()}"></pli-button>
                    </div>
                    ${when(
                        Number(this.lifespan),
                        () => html`
                            <div class="timer">
                                <div class="timer-inner"></div>
                            </div>
                        `,
                    )}
                </div>
            </div>
        `;
    }
}

export type PliToastOrdered = PliToastProps & { id: number };

@customElement('pli-toast-container')
class PliToastContainer extends LitElement {
    @property({ type: Array })
    items: PliToastOrdered[] = [];

    static styles = [
        styles.position,
        styles.flex,
        styles.grid,
        styles.padding,
        css`
            :host {
                position: fixed;
                display: block;
                top: 0;
                right: 0;
                z-index: 100;
                width: 100%;
            }
        `,
    ];

    removeItemById(id: number) {
        this.items = [...this.items.filter((item) => item.id !== id)];
        this.dispatchEvent(new CustomEvent('toast-remove', { composed: true, detail: id }));
    }

    handleClick(id: number) {
        this.removeItemById(id);
    }

    handleTimer(id: number) {
        this.removeItemById(id);
    }

    render() {
        return html`<div class="grid-vertical gap-1 p-1-5">
            ${this.items.map(
                (item, index) => html`
                    <pli-toast
                        style="--delay: ${index * 15}ms"
                        .bodyText="${item.bodyText}"
                        .title="${item.title}"
                        .lifespan="${item.lifespan}"
                        @remove="${() => this.removeItemById(item.id)}"
                        @timer-finished="${() => this.removeItemById(item.id)}"
                    ></pli-toast>
                `,
            )}
        </div>`;
    }
}
