import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { flexStyles } from './';
import { PliUserBubbleProps } from './pli-user-bubble';
import './pli-user-bubble';

export interface PliUserBubbleListProps {
    items: PliUserBubbleProps[];
}

@customElement('pli-user-bubble-list')
class PliUserBubbleList extends LitElement implements PliUserBubbleListProps {
    @property({ type: Array })
    items: PliUserBubbleProps[];

    static styles = [
        flexStyles,
        css`
            .bubble-wrapper:not(:first-child) {
                --negative-offset: calc(var(--size-1) * -1);
                margin-left: var(--negative-offset);
                position: relative;
            }
        `,
    ];
    render() {
        const createDescendingZIndex = (index: number): string => `z-index: ${this.items.length + index}`;
        const hasItems = Boolean(this.items.length);
        return html` <div class="flex">
            ${hasItems
                ? this.items.map(
                      (item, index) =>
                          html` <div class="bubble-wrapper" style="${createDescendingZIndex(index)}">
                              <pli-user-bubble
                                  firstName="${item.firstName}"
                                  lastName="${item.lastName}"
                                  userId="${item.userId}"
                              ></pli-user-bubble>
                          </div>`,
                  )
                : html` <pli-user-bubble></pli-user-bubble>`}
        </div>`;
    }
}
