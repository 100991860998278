import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { baseStyles, flexStyles, gridStyles } from './';
import './pli-button';
import './pli-icon';
import { classMap } from 'lit/directives/class-map.js';
import { useTotalPagesCount } from '../utils/use-pagination-helpers';

export interface PliPaginationProps {
    page: number;
    total: number;
}

@customElement('pli-pagination')
class Pagination extends LitElement implements PliPaginationProps {
    @property({ type: Number })
    page: number = 1;
    @property({ type: Number })
    total: number;

    _isActivePage = (i: number) => i === this.page;

    @state()
    totalPages = 0;
    @state()
    boundaryCount = 1;
    @state()
    siblingCount = 1;
    @state()
    items: number[] = [];

    private get isFirstPage() {
        return this._isActivePage(1);
    }
    private get isLastPage() {
        return this._isActivePage(this.totalPages);
    }

    _previous() {
        if (this.isFirstPage) {
            return;
        }

        this.showPage(this.page - 1);
    }
    _next() {
        if (this.isLastPage) {
            return;
        }

        this.showPage(this.page + 1);
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.setTotalPages();
        this.updatePages();
    }

    private setTotalPages() {
        const { count } = useTotalPagesCount(this.total);
        this.totalPages = count;
    }

    _range(start: number, end: number) {
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    }

    private updatePages() {
        const startPages = this._range(1, Math.min(this.boundaryCount, this.totalPages));
        const endPages = this._range(
            Math.max(this.totalPages - this.boundaryCount + 1, this.boundaryCount + 1),
            this.totalPages,
        );

        const siblingsStart = Math.max(
            Math.min(
                // Natural start
                this.page - this.siblingCount,
                // Lower boundary when page is high
                this.totalPages - this.boundaryCount - this.siblingCount * 2 - 1,
            ),
            // Greater than startPages
            this.boundaryCount + 2,
        );

        const siblingsEnd = Math.min(
            Math.max(
                // Natural end
                this.page + this.siblingCount,
                // Upper boundary when page is low
                this.boundaryCount + this.siblingCount * 2 + 2,
            ),
            // Less than endPages
            endPages.length > 0 ? endPages[0] - 2 : this.totalPages - 1,
        );

        this.items = [
            ...startPages,

            // Start ellipsis
            ...(siblingsStart > this.boundaryCount + 2
                ? [null]
                : this.boundaryCount + 1 < this.totalPages - this.boundaryCount
                  ? [this.boundaryCount + 1]
                  : []),

            // Sibling pages
            ...this._range(siblingsStart, siblingsEnd),

            // End ellipsis

            ...(siblingsEnd < this.totalPages - this.boundaryCount - 1
                ? [null]
                : this.totalPages - this.boundaryCount > this.boundaryCount
                  ? [this.totalPages - this.boundaryCount]
                  : []),

            ...endPages,
        ];
    }

    showPage(page: number) {
        this.page = page;
        const event: PliPaginationChangeEvent = new CustomEvent('pagination-changed', {
            composed: true,
            detail: {
                page,
            },
        });
        this.dispatchEvent(event);
        this.updatePages();
    }

    static styles = [
        baseStyles,
        flexStyles,
        gridStyles,
        css`
            li {
                list-style-type: none;
            }

            li + li {
                margin-left: -1px;
            }

            li {
                flex-shrink: 0;
                --size: var(--size-2-5);
                width: var(--size);
                height: var(--size);
            }

            button {
                appearance: none;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: var(--border-default);
                background-color: var(--color-white);
                color: var(--color-black);
            }

            button:not(.active):not(:disabled):hover {
                background-color: var(--button-background-secondary-hover);
            }

            button:disabled:not(.active) {
                opacity: 0.5;
            }

            button.active {
                background-color: var(--button-background-primary);
                color: var(--button-foreground-primary);
                border: 0;
            }

            li:first-child button {
                border-top-left-radius: var(--radius-md);
                border-bottom-left-radius: var(--radius-md);
            }
            li:last-child button {
                border-top-right-radius: var(--radius-md);
                border-bottom-right-radius: var(--radius-md);
            }

            .root {
                position: relative;
            }

            .total {
                position: absolute;
                padding-right: var(--size-1);
                left: 0;
                transform: translateX(-100%);
                height: 100%;
                display: flex;
                align-items: center;
            }
        `,
    ];

    _renderItem(i: number) {
        const classes = classMap({
            active: this._isActivePage(i),
        });

        if (!Number(i)) {
            return html` <button disabled>...</button> `;
        }

        return html`
            <button
                aria-label="Go to page ${i}"
                class="${classes}"
                ?disabled="${this._isActivePage(i)}"
                @click="${() => this.showPage(i)}"
            >
                ${i}
            </button>
        `;
    }

    render() {
        return html`
            <div class="flex justify-center">
                <div class="root">
                    <div class="total">
                        <pli-text>Total ${this.total} items</pli-text>
                    </div>
                    <ul class="flex items-center">
                        <li>
                            <button
                                aria-label="Go to previous page"
                                @click="${() => this._previous()}"
                                ?disabled="${this.isFirstPage}"
                            >
                                <pli-icon name="chevron-left" slot="icon-left"></pli-icon>
                            </button>
                        </li>
                        ${this.items.map((i) => html`<li>${this._renderItem(i)}</li>`)}
                        <li>
                            <button
                                aria-label="Go to previous page"
                                @click="${() => this._next()}"
                                ?disabled="${this.isLastPage}"
                            >
                                <pli-icon name="chevron-right" slot="icon-left"></pli-icon>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        `;
    }
}

export type PliPaginationChangeEvent = CustomEvent<{ page: number }>;
