import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PliIconProps } from './pli-icon';
import './pli-icon';
import { styles } from './styles';
import { when } from 'lit/directives/when.js';

export const availableVariants = ['info', 'success', 'error', 'warning'] as const;
type PliAlertBoxVariant = (typeof availableVariants)[number];

export const availabledisplayModes = ['icon-text', 'text'] as const;
type PliAlertBoxDisplayMode = (typeof availabledisplayModes)[number];

export type PliAlertBoxProps = {
    variant?: PliAlertBoxVariant;
    displayMode?: PliAlertBoxDisplayMode;
};

@customElement('pli-alert-box')
class PliAlertBox extends LitElement implements PliAlertBoxProps {
    static styles = [
        styles.flex,
        styles.grid,
        styles.rounded,
        css`
            :host {
                --background-info: #eff6ff;
                --foreground-info: #1e40af;
                --background-error: #f8d7da;
                --foreground-error: #842029;
                --background-success: #d2f4ea;
                --foreground-success: #0d503c;
                --background-warning: #fff3cd;
                --foreground-warning: #997404;
                color: var(--foreground);
            }

            .alert-box {
                padding: var(--size-2) var(--size-1);
                background-color: var(--background);
            }
        `,
    ];

    @property()
    variant?: PliAlertBoxVariant;

    @property()
    displayMode?: PliAlertBoxDisplayMode = 'icon-text';

    render() {
        const iconMap: Record<PliAlertBoxVariant, PliIconProps['name']> = {
            error: 'x-octagon',
            info: 'info-circle',
            success: 'check-circle',
            warning: 'exclamation-triangle',
        };

        const variant = this.variant ?? 'info';
        const iconName = iconMap[variant];

        this.style.setProperty('--background', `var(--background-${variant})`);
        this.style.setProperty('--foreground', `var(--foreground-${variant})`);

        return html` <div class="rounded-sm flex gap-1 alert-box" role="alert">
            ${when(this.displayMode === 'icon-text', () => html`<pli-icon size="24" name="${iconName}"></pli-icon>`)}
            <slot></slot>
        </div>`;
    }
}
