import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { styles } from './styles';
import './pli-icon';
import './pli-text';

export interface PliDragDropProps {
    maximumFileSize?: number;
}

@customElement('pli-drag-drop')
class DragDrop extends LitElement implements PliDragDropProps {
    static styles = [
        styles.base,
        styles.grid,
        styles.flex,
        styles.position,
        css`
            :host {
                --bg-highlighted: var(--color-white);
                --bg-initial: var(--color-light-gray);
            }
            .drag-drop {
                border: var(--border-dashed);
                border-radius: var(--radius-md);
                padding: var(--size-4);
                background-color: var(--bg);
            }

            input {
                opacity: 0;
            }
            label {
                display: inline-flex;
                margin: 0 auto;
                box-shadow: none;
                padding: var(--size-1) var(--size-1-5);
                background-color: var(--button-background-primary);
                color: var(--button-foreground-primary);
                width: var(--width);
                height: 100%;
                border-radius: var(--radius-md);
                cursor: pointer;
            }
            label span {
                z-index: 1;
            }
            label:hover {
                background-color: var(--button-background-primary-hover);
                color: var(--button-foreground-primary-hover);                
            }
            label:focus-within {
                outline: var(--color-off-black) auto 1px;
            }
        `,
    ];

    @property({ type: Number })
    maximumFileSize: number = 10;

    @state()
    _isHighLighted = false;

    _inputFileRef: Ref<HTMLInputElement> = createRef();

    get maximumFileSizeInMB() {
        const MB_BYTES = 1_048_576;
        return this.maximumFileSize * MB_BYTES;
    }

    get acceptedFileTypes(): string[] {
        return [
            'xml', 'xls', 'xlsx', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf', 'rtf', 'aspx', 'csv', 'mp3', 'avi'
        ]
    }

    _handleFiles(files: File[]) {
        const filesOverSizeLimit = files.filter((file) => file.size > this.maximumFileSizeInMB);

        if (filesOverSizeLimit.length) {
            return this.dispatchEvent(
                new CustomEvent('filesFail', {
                    composed: true,
                    detail: {
                        value: filesOverSizeLimit.map((file) => ({
                            name: file.name,
                            message: `The attached file: ${file.name} exceeds our file size limit (${this.maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
                        })),
                        maximumFileSize: this.maximumFileSize,
                    },
                }),
            );
        }

        const event: FileUpdateEvent = new CustomEvent('filesUpdate', {
            composed: true,
            detail: {
                value: files,
                maximumFileSize: this.maximumFileSize,
            },
        });

        this.dispatchEvent(event);
    }

    onDrop = (event: DragEvent) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);

        this._handleFiles(files);
        this.setHighlighted(false);
    };

    onChange = (event: Event) => {
        const files = Array.from(this._inputFileRef.value.files);
        this._handleFiles(files);
    };

    handleDrag = (event: DragEvent) => {
        event.preventDefault();
        this.setHighlighted(true);
    };

    setHighlighted = (payload: boolean) => (this._isHighLighted = payload);

    render() {
        const { onDrop, onChange, handleDrag, _isHighLighted, _inputFileRef, acceptedFileTypes } = this;
        const cssPropertyName = _isHighLighted ? '--drop-area-bg-highlight' : '--drop-area-bg-initial';
        const inputAccept = '.' + acceptedFileTypes.join(',.');
        const allowedFileTypes = acceptedFileTypes.join(', ');
        this.style.setProperty('--bg', `var(${cssPropertyName})`);

        return html` <div
            @drop="${onDrop}"
            @dragenter="${handleDrag}"
            @dragover="${handleDrag}"
            @dragleave="${handleDrag}"
            class="drag-drop"
        >
            <div class="flex flex-col items-center gap-2">
                <pli-icon name="cloud-arrow-up-fill" size="48"></pli-icon>
                <pli-text as="p">Drag & drop your files here</pli-text>
                <pli-text as="p">OR</pli-text>
                <label class="relative" for="file"
                    ><input
                        ${ref(_inputFileRef)}
                        multiple
                        class="absolute"
                        id="file"
                        type="file"
                        @change="${onChange}"
                        accept="${inputAccept}"
                    /><span>Browse</span></label
                >
                <pli-text>Max file size: 10 MB</pli-text>
                <pli-text>Allowed file types: ${allowedFileTypes}</pli-text>
            </div>
        </div>`;
    }
}

export type FileUpdateEvent = CustomEvent<{ value: File[]; maximumFileSize: number }>;