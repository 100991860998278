import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './styles';

export interface PliProgressStepperProps {
    steps: number;
    currentStep: number;
}

@customElement('pli-progress-stepper')
class PliProgressStepper extends LitElement implements PliProgressStepperProps {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .step {
                --background: var(--color-border);
                --foreground: var(--color-black);
                line-height: 1;
                position: relative;
            }

            .step:not(:first-child) {
                display: flex;
                align-items: center;
                flex: 1;
            }

            .circle {
                --size: var(--size-3);
                border-radius: var(--radius-full);
                width: var(--size);
                height: var(--size);
                background-color: var(--background);
                color: var(--foreground);
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .step:not(:first-child):before {
                content: '';
                display: block;
                flex: 1;
                flex-shrink: 0;
                height: var(--size-0-5);
                background-color: var(--background);
            }

            .active {
                --background: var(--button-background-primary);
                --foreground: var(--color-white);
            }
            .active .circle {
                background-color: var(--background);
                color: var(--foreground);
            }

            .progress-stepper {
                height: 100%;
            }
        `,
    ];
    @property({ type: Number })
    steps: number;

    @property({ type: Number })
    currentStep: number;

    render() {
        const { steps, currentStep } = this;
        const displayedSteps = [...Array(steps).keys()];
        return html`<div class="flex progress-stepper items-center">
            ${displayedSteps.map(
                (key) =>
                    html`<div class="step ${key <= currentStep ? 'active' : null}">
                        <div class="circle">${key + 1}</div>
                    </div>`,
            )}
        </div>`;
    }
}
