import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { PliIconProps } from './pli-icon';
import './pli-icon';
import { styles } from './styles';

export interface PliCheckBoxProps {
    value: string;
    checked: boolean;
}

@customElement('pli-checkbox')
class PliCheckBox extends LitElement implements PliCheckBoxProps {
    static styles = [
        styles.position,
        styles.flex,
        styles.grid,
        css`
            input {
                opacity: 0;
            }

            pli-icon {
                transition: box-shadow 150ms ease-in-out;
            }

            input:focus + label pli-icon {
                box-shadow: var(--input-border-focus-shadow);
                border-radius: var(--radius-sm);
            }

            label {
                padding: var(--size-1) var(--size-0-5);
            }

            label:hover {
                background-color: var(--bs-gray-100);
            }
        `,
    ];
    @property()
    value: string;
    @property({ type: Boolean })
    checked: boolean;

    inputRef: Ref<HTMLInputElement> = createRef();

    onChange = () => {
        const checked = this.inputRef.value.checked;
        const value = this.inputRef.value.value;

        const dispatchedEvent: PliCheckboxUpdateEvent = new CustomEvent('change', {
            composed: true,
            detail: {
                value,
                checked,
            },
        });
        this.dispatchEvent(dispatchedEvent);
    };
    render() {
        const { value, onChange, inputRef, checked } = this;
        const id = `id-${value}`;
        const icon: PliIconProps['name'] = checked ? 'check-square-fill' : 'square';

        return html`<div class="relative">
            <input
                type="checkbox"
                class="absolute"
                .checked="${checked}"
                value="${value}"
                id="${id}"
                ${ref(inputRef)}
                @change="${onChange}"
            /><label class="flex items-center gap-05" for="${id}">
                <pli-icon .name="${icon}"></pli-icon>
                <div class="flex-1"><slot></slot></div>
            </label>
        </div>`;
    }
}

export type PliCheckboxUpdateEvent = CustomEvent<{ value: string; checked: boolean }>;
