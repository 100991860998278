import { LitElement, PropertyValues, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';
import {
    PliRoundedElementProps,
    PliRoundedElementRadius,
    createRoundedElementClassnames,
} from './utils/create-rounded-element-classnames';
import { PliInputController } from './controllers/input-controller';
import { styles } from './styles';
import { PliInputState } from './types/input-state';
import { createRef, ref, Ref } from 'lit/directives/ref.js';

export type PliTextareaProps = PliRoundedElementProps<{
    placeholder?: string;
    state?: PliInputState;
    value: string;
}>;

@customElement('pli-textarea')
export class PliTextarea extends LitElement implements PliTextareaProps {
    @property()
    placeholder = '';

    @property()
    value = '';

    @property()
    state?: PliInputState = 'initial';

    @property()
    radiusTopRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomLeft?: PliRoundedElementRadius = 'md';

    @property()
    radiusTopLeft?: PliRoundedElementRadius = 'md';

    @property({ type: Boolean, reflect: true })
    disabled: boolean = false;

    @property({ type: Boolean })
    focusOnMount: boolean = false;

    ref: Ref<HTMLTextAreaElement> = createRef();

    controller: PliInputController = new PliInputController(this, this.ref);

    focusElement() {
        this.controller.focusElement();
    }

    protected updated(_changedProperties: PropertyValues): void {
        if (!this.focusOnMount) {
            return;
        }

        this.focusElement();
    }

    static styles = [
        baseStyles,
        styles.rounded,
        css`
            :host {
                display: block;
                width: 100%;
                --border: var(--border-default);
                --focus-box-shadow: var(--input-border-focus-shadow);
            }
            textarea {
                width: inherit;
                background-color: var(--bs-body-bg, var(--body-bg));
                align-self: stretch;
                border: var(--border);
                border-radius: var(--radius-md);
                padding: var(--size-1) var(--size-0-75);
                font-size: var(--font-size-body);
                transition: box-shadow 150ms ease-in-out;
                resize: vertical;
            }

            textarea:focus {
                box-shadow: var(--focus-box-shadow);
                outline: 0;
            }
        `,
    ];

    _createStyleFromState() {
        this.style.setProperty('--border', `var(--border-${this.state})`);
        this.style.setProperty('--focus-box-shadow', `var(--input-border-focus-shadow-${this.state})`);
    }

    render() {
        this._createStyleFromState();
        const { radiusBottomLeft, radiusBottomRight, radiusTopLeft, radiusTopRight, disabled } = this;
        const { classNames: classes } = createRoundedElementClassnames({
            radiusBottomLeft,
            radiusBottomRight,
            radiusTopLeft,
            radiusTopRight,
        });

        return html`<textarea
            ${ref(this.ref)}
            class="${classes}"
            placeholder="${this.placeholder}"
            @input="${this.controller.inputHandler}"
            ?disabled="${disabled}"
            .value="${this.value}"
        ></textarea>`;
    }
}
