import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import './pli-card';
import './pli-text';
import './pli-button';
import './pli-icon';

import { flexStyles } from './';
import './pli-input';
import { PliInputChangeEvent } from './controllers/input-controller';

export interface PliSearchFormProps {
    placeholder: string;
    value: string;
}

@customElement('pli-search-form')
export class PliSearchForm extends LitElement implements PliSearchFormProps {
    @property()
    placeholder = 'Placeholder';

    @property()
    value = '';

    @state()
    _value = '';

    static styles = [
        flexStyles,
        css`
            .pli-search-form {
                display: grid;
                grid-template-columns: 1fr auto;
            }

            input {
                background-color: var(--bs-body-bg, var(--body-bg));
                align-self: stretch;
                border: 1px solid var(--bs-gray-200, var(--input-border-color));
                border-radius: var(--radius-md);
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: var(--size-1) var(--size-0-75);
                font-size: var(--font-size-body);
                transition: box-shadow 150ms ease-in-out;
            }

            input:focus {
                box-shadow: var(--input-border-focus-shadow);
                outline: 0;
            }
        `,
    ];

    connectedCallback(): void {
        super.connectedCallback();
        this._value = this.value;
    }

    search() {
        this.dispatchEvent(
            new CustomEvent('search', {
                detail: {
                    value: this._value,
                },
                bubbles: true,
                composed: true,
            }),
        );
    }

    _keyUpHandler(event: PliInputChangeEvent) {
        this._value = event.detail.value;
    }

    render() {
        return html`
            <form class="pli-search-form">
                <pli-input
                    @change="${this._keyUpHandler}"
                    value="${this._value}"
                    placeholder="${this.placeholder}"
                    radiusTopRight="0"
                    radiusBottomRight="0"
                ></pli-input>
                <pli-button radiusTopLeft="0" radiusBottomLeft="0" style="" size="lg" .onClick="${() => this.search()}">
                    <pli-icon name="search" slot="icon-left"></pli-icon>
                    Search
                </pli-button>
            </form>
        `;
    }
}
