import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './pli-pagination';
import { PliPaginationChangeEvent } from './pli-pagination';
import { styles } from './styles';
import { useSkip, useTotalPagesCount } from 'utils/use-pagination-helpers';
import { when } from 'lit/directives/when.js';

@customElement('pli-pager')
class PliPager extends LitElement {
    static styles = [styles.grid, styles.flex, styles.list];

    @property({ type: Number })
    page: number = 1;

    @property({ type: Number })
    total: number = 0;

    @property({ type: Array })
    items: unknown[] = [];

    @property()
    emptyResultMessage = 'There is no result to show';

    handlePagination(page: number) {
        this.dispatchEvent(
            new CustomEvent('page-update', {
                composed: true,
                detail: {
                    page,
                    skip: this.skip,
                },
            }),
        );
    }

    get itemCount() {
        return this.items.length;
    }
    get skip() {
        return useSkip(this.page);
    }

    render() {
        const { isPaginationEnabled } = useTotalPagesCount(this.total);

        return html`
            <div class="grid-vertical gap-1">
                <slot></slot>
                ${when(this.itemCount === 0, () => html`<pli-text>${this.emptyResultMessage}</pli-text>`)}
                ${when(
                    isPaginationEnabled,
                    () => html`
                        <pli-pagination
                            .total="${this.total}"
                            page="${this.page}"
                            @pagination-changed="${(event: PliPaginationChangeEvent) =>
                                this.handlePagination(event.detail.page)}"
                        ></pli-pagination>
                    `,
                )}
            </div>
        `;
    }
}

export type PliPagerUpdatedEvent = CustomEvent<{ page: number; skip: number }>;
