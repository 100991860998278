import { LitElement, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { baseStyles } from '.';
import { classMap } from 'lit/directives/class-map.js';

export const availableTextStyleVariants = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body-large',
    'body',
    'caption',
] as const;

export const availableElements = ['span', 'p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

type PliTextVariant = (typeof availableTextStyleVariants)[number];
type PliTextElement = (typeof availableElements)[number];

export interface PliTextProps {
    as?: PliTextElement;
    variant?: PliTextVariant;
}

@customElement('pli-text')
class PliText extends LitElement implements PliTextProps {
    @property({ type: String })
    as?: PliTextElement = 'span';

    @property({ type: String })
    variant?: PliTextVariant = 'body';

    static styles = [
        baseStyles,
        css`
            .h1,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6 {
                line-height: 120%;
                font-weight: 500;
            }

            .h1 {
                font-size: var(--font-size-h1);
            }

            .h2 {
                font-size: var(--font-size-h2);
            }

            .h3 {
                font-size: var(--font-size-h3);
            }

            .h4 {
                font-size: var(--font-size-h4);
            }

            .h5 {
                font-size: var(--font-size-h5);
            }

            .h6 {
                font-size: var(--font-size-h6);
            }

            .body {
                font-size: var(--font-size-body);
                font-weight: 400;
            }

            .body-large {
                font-size: var(--font-size-body-large);
                font-weight: 400;
            }

            .caption {
                font-size: var(--font-size-caption);
                font-weight: 700;
            }
        `,
    ];

    render() {
        const { as, variant } = this;
        const tag = unsafeStatic(as ?? 'span');

        const classes = {
            'pli-text': true,
            [`${variant}`]: true,
        };

        return html`
            <${tag} class="${classMap(classes)}">
                <slot></slot>
            </${tag}>
        `;
    }
}
