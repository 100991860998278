import { LitElement, PropertyValues, TemplateResult, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './pli-text';
import { createRef, ref, Ref } from 'lit/directives/ref.js';
import './pli-button';
import { styles } from './styles';
import './pli-icon';
import { PliButton, PliButtonProps } from './pli-button';
import { when } from 'lit/directives/when.js';
import { PliElementPadding } from './types/element-padding';
import { classMap } from 'lit/directives/class-map.js';

export const availableDialogGradientModes = ['visible', 'hidden'] as const;
export const availableDialogModalHeights = ['hug-content', 'fixed', 'full'] as const;
export const availableDialogModalWidths = ['sm', 'md', 'lg'] as const;

export type PliDialogGradientVariant = (typeof availableDialogGradientModes)[number];
export type PliDialogModalHeight = (typeof availableDialogModalHeights)[number];
export type PliDialogModalWidth = (typeof availableDialogModalWidths)[number];
export interface PliDialogProps {
    gradient?: PliDialogGradientVariant;
    padding?: PliElementPadding;
    modalHeight?: PliDialogModalHeight;
    modalWidth?: PliDialogModalWidth;
}

@customElement('pli-dialog')
class PliDialog extends LitElement implements PliDialogProps {
    @property()
    gradient?: PliDialogGradientVariant = 'hidden';

    dialogRef: Ref<HTMLDialogElement> = createRef();

    @property({ type: Boolean })
    open?: HTMLDialogElement['open'];

    @property()
    padding?: PliElementPadding = 'default';

    @property()
    modalHeight?: PliDialogModalHeight = 'hug-content';

    @property()
    modalWidth?: PliDialogModalWidth = 'sm';

    static styles = [
        styles.flex,
        styles.padding,
        css`
            :host {
                --animation-appear-bounce: appear-bounce 0.35s cubic-bezier(0, 0.6, 0, 1.2) forwards;
                --animation-blur-in: blur-in 0.35s cubic-bezier(0.06, 0.55, 0.27, 0.99) forwards;
                --animation-blur-out: blur-out 0.35s cubic-bezier(0.06, 0.55, 0.27, 0.99) forwards;
                --animation-slide-in: slide-in 0.35s cubic-bezier(0.06, 0.55, 0.27, 0.99) forwards;
                --animation-scale-out: scale-out 0.2s cubic-bezier(0.06, 0.55, 0.27, 0.99) forwards;
                --modal-container-width: calc(100vw - var(--sidebar-width));
                --modal-padding-x: var(--size-1-5);
                --modal-padding-y: var(--size-2);
                --blur-none: blur(0);
                --blur-amount: blur(1rem);
                --gradient-height: 112px;
                --gradient-transparent-to-white: linear-gradient(
                    to top,
                    var(--color-white) 0%,
                    rgba(255, 255, 255, 0.738) 19%,
                    rgba(255, 255, 255, 0.541) 34%,
                    rgba(255, 255, 255, 0.382) 47%,
                    rgba(255, 255, 255, 0.278) 56.5%,
                    rgba(255, 255, 255, 0.194) 65%,
                    rgba(255, 255, 255, 0.126) 73%,
                    rgba(255, 255, 255, 0.075) 80.2%,
                    rgba(255, 255, 255, 0.042) 86.1%,
                    rgba(255, 255, 255, 0.021) 91%,
                    rgba(255, 255, 255, 0.008) 95.2%,
                    rgba(255, 255, 255, 0.002) 98.2%,
                    rgba(255, 255, 255, 0) 100%
                );
                --content-bottom-padding: var(--gradient-height);
                --dialog-fixed-height: 35rem;
                --dialog-max-height: calc(100vh - calc(var(--size-3) * 2));
                --dialog-min-width: 35rem;
                --dialog-width-sm: calc(var(--modal-container-width) * 0.333333333);
                --dialog-width-md: calc(var(--modal-container-width) * 0.5);
                --dialog-width-lg: calc(var(--modal-container-width) * 0.75);
            }

            * {
                box-sizing: border-box;
            }

            @keyframes scale-out {
                from {
                    opacity: 1;
                }
                to {
                    transform: scale(0.9);
                    opacity: 0;
                }
            }

            @keyframes slide-in {
                from {
                    transform: translateY(10%);
                    opacity: 0;
                }
                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            @keyframes blur-in {
                from {
                    background-color: rgba(0, 0, 0, 0);
                    backdrop-filter: var(--blur-none);
                }
                to {
                    backdrop-filter: var(--blur-amount);
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }

            @keyframes blur-out {
                from {
                    backdrop-filter: var(--blur-amount);
                    background-color: rgba(0, 0, 0, 0.5);
                }
                to {
                    backdrop-filter: var(--blur-none);
                    background-color: rgba(0, 0, 0, 0);
                }
            }

            @keyframes appear-bounce {
                from {
                    transform: translateY(20%);
                    opacity: 0;
                }
                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            @keyframes scale-in {
                from {
                    transform: scale(0.9);
                    opacity: 0;
                }
                to {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            div:has(slot[name='success']) {
                animation: var(--animation-appear-bounce);
            }

            div:has(slot[name='error']) {
                animation: var(--animation-appear-bounce);
            }

            dialog::backdrop {
                animation: var(--animation-blur-in);
            }

            dialog.closing::backdrop {
                animation: var(--animation-blur-out);
            }

            dialog {
                appearance: none;
                border-radius: var(--radius-md);
                border: 0;
                padding: 0;
                min-width: var(--dialog-min-width);
                width: var(--dialog-width);
                transition: opacity 0.5s ease-out;
                animation: var(--animation-slide-in);
                max-height: var(--dialog-max-height);
                overflow-y: auto;
            }

            dialog.height-hug {
                height: auto;
            }
            dialog.height-fixed {
                height: var(--dialog-fixed-height);
            }

            dialog.width-sm {
                width: var(--dialog-width-sm);
            }
            dialog.width-md {
                width: var(--dialog-width-md);
            }
            dialog.width-lg {
                width: var(--dialog-width-lg);
            }

            dialog[open].closing {
                animation: var(--animation-scale-out);
            }

            dialog[open] {
                display: grid;
            }

            dialog:not([open]) {
                pointer-events: none;
                opacity: 0;
            }

            header {
                position: sticky;
                top: 0;
                display: flex;
                justify-content: flex-end;
            }

            button[autofocus] {
                --close-button-dimension: var(--size-4-5);
                appearance: none;
                background: none;
                border: 0;
                padding: 0;
                width: var(--close-button-dimension);
                height: var(--close-button-dimension);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .footer-buttons {
                gap: var(--size-1);
                position: sticky;
                bottom: 0;
            }

            .footer-buttons > * {
                flex: 1;
            }

            footer {
                background-color: var(--color-white);
                padding: var(--modal-padding-x) var(--modal-padding-x) var(--modal-padding-y);
                position: relative;
            }

            .gradient,
            .gradient-spacer {
                height: var(--gradient-height);
            }

            .gradient {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                pointer-events: none;
                background: var(--gradient-transparent-to-white);
                transform: translateY(-100%);
            }

            form {
                display: flex;
                flex-direction: column;
            }

            .scroll-wrapper {
                flex: 1;
            }
            .content {
                padding-bottom: var(--modal-padding-y);
            }
        `,
    ];

    get dialog(): HTMLDialogElement {
        return this.dialogRef.value;
    }

    registerEventListeners() {
        this.shadowRoot.addEventListener('pli-dialog-primary-dismiss', () => {
            this.dispatchEvent(new CustomEvent('primary-dismiss', { composed: true }));
            this.closeDialog();
        });

        this.shadowRoot.addEventListener('pli-dialog-close', () => {
            this.closeDialog();
        });

        this.shadowRoot.addEventListener('pli-dialog-open', () => {
            this.openDialog();
        });
    }

    unregisterEventListeners() {
        this.shadowRoot.removeEventListener('pli-dialog-primary-dismiss', () => {
            this.closeDialog();
        });

        this.shadowRoot.removeEventListener('pli-dialog-close', () => {
            this.closeDialog();
        });

        this.shadowRoot.removeEventListener('pli-dialog-open', () => {
            this.openDialog();
        });
    }

    connectedCallback() {
        super.connectedCallback();
        this.registerEventListeners();
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        if (this.open) {
            this.openDialog();
        }
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.unregisterEventListeners();
        this.dialog?.removeEventListener('click', (event) => this.handleClick(event));
    }

    handleClick(event: MouseEvent) {
        const composed = event.composedPath();
        const eventTarget = composed[0];
        const isBackdropClicked = eventTarget === this.dialog;

        if (isBackdropClicked) {
            this.closeDialog();
        }
    }

    preventBodyScroll() {
        document.body.classList.add('dialog-open');
    }

    resetBodyScroll() {
        document.body.classList.remove('dialog-open');
    }

    get focusableElements() {
        // dialog activeEl in the first layer of shadow DOM and closebutton with autofocus
        const firstActiveElement = this.shadowRoot.activeElement as HTMLElement;
        const closeButton = this.dialog.querySelector('button[autofocus]') as HTMLButtonElement;

        // get all slots that are used for buttons in dialog that are not empty
        const slots = Array.from(this.dialog.querySelectorAll('slot[name*=button]')) as HTMLSlotElement[];
        const slotsWithButtons = slots.filter((slot) => slot.assignedElements().length > 0);

        const lastCustomElement = slotsWithButtons[slotsWithButtons.length - 1]
            .assignedElements()[0]
            .shadowRoot.querySelector('pli-button');

        // since this.shadowRoot.activeElement doesn't pierce through slotted content
        const lastActiveElement = lastCustomElement.shadowRoot.activeElement as HTMLElement;

        // since this.shadowRoot.activeElement doesn't pierce slotted content we need to find the actual semantic button to focus
        const lastButton = lastCustomElement.shadowRoot.querySelector('button');

        return { closeButton, lastButton, firstActiveElement, lastActiveElement };
    }

    openDialog() {
        this.open = true;

        this.dialog.showModal();
        this.preventBodyScroll();

        this.dialog.addEventListener('keydown', (e) => this.handleKeyDown(e));
        this.dialog.addEventListener('click', (event) => this.handleClick(event));

        this.dialog.addEventListener('cancel', () => {
            this.resetBodyScroll();
        });
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            this.trapFocus(event);
        }
    }

    trapFocus(event: KeyboardEvent) {
        const { firstActiveElement, closeButton, lastActiveElement, lastButton } = this.focusableElements;

        if (event.shiftKey && firstActiveElement === closeButton) {
            // If shift-tabbing from the first focusable element, go to the last
            lastButton.focus();
            event.preventDefault();
        } else if (!event.shiftKey && lastActiveElement === lastButton) {
            // If tabbing from the last focusable element, go to the first
            closeButton.focus();
            event.preventDefault();
        }
    }

    async closeDialog() {
        this.resetBodyScroll();
        const closeAnimation = () =>
            new Promise<void>((resolve) => {
                const onAnimationEnd = () => {
                    this.dialog.removeEventListener('animationend', onAnimationEnd);
                    resolve();
                };
                this.dialog.addEventListener('animationend', onAnimationEnd);
                this.dialog.classList.add('closing');
            });

        await closeAnimation();

        this.dialog.classList.remove('closing');
        this.dialog.close();

        this.dialog.removeEventListener('click', (event) => this.handleClick(event));

        this.open = false;
        this.dispatchEvent(new CustomEvent('close'));
    }

    get gradientIsVisible() {
        return this.gradient === 'visible';
    }

    render() {
        const scrollContentClasses = classMap({
            'px-1-5': this.padding === 'default',
        });
        const dialogModalClasses = classMap({
            'height-fixed': this.modalHeight === 'fixed',
            'height-full': this.modalHeight === 'full',
            'width-sm': this.modalWidth === 'sm',
            'width-md': this.modalWidth === 'md',
            'width-lg': this.modalWidth === 'lg',
        });
        return html`<slot name="open-button"></slot>
            <dialog ${ref(this.dialogRef)} class="${dialogModalClasses}">
                <form method="dialog">
                    <header>
                        <button
                            ?autofocus="${this.open}"
                            @click="${(event: MouseEvent) => {
                                event.preventDefault();
                                this.closeDialog();
                            }}"
                            aria-label="Close dialog"
                        >
                            <pli-icon size="24" name="x"></pli-icon>
                        </button>
                    </header>
                    <div class="scroll-wrapper">
                        <div class="content ${scrollContentClasses}">
                            <slot></slot>
                            ${when(this.gradientIsVisible, () => html` <div class="gradient-spacer"></div>`)}
                        </div>
                    </div>
                    <footer class="flex footer-buttons">
                        ${when(this.gradientIsVisible, () => html` <div class="gradient"></div>`)}
                        <slot name="secondary-button"></slot>
                        <slot name="primary-button"></slot>
                    </footer>
                </form>
            </dialog>`;
    }
}

@customElement('pli-dialog-content')
class PliDialogContent extends LitElement {
    static styles = [
        styles.grid,
        styles.padding,
        styles.divider,
        styles.flex,
        css`
            :host {
                --illustration-size: 11.25rem;
                overflow: hidden;
            }

            ::slotted(*[slot='error-icon']) {
                color: var(--color-rojo-red);
            }
            ::slotted(*[slot='illustration']) {
                width: var(--illustration-size);
                margin: 0 auto;
            }
        `,
    ];

    render() {
        return html`<div class="grid-vertical gap-1">
            <slot name="illustration"></slot>
            <slot name="icon"></slot>
            <slot name="error-icon"></slot>
            <div class="grid-vertical gap-05">
                <slot></slot>
            </div>
        </div>`;
    }
}

@customElement('pli-dialog-close-button')
class PliDialogCloseButton extends LitElement {
    @property()
    variant?: PliButtonProps['variant'] = 'secondary';

    static styles = [
        css`
            :host {
                width: 100%;
            }
        `,
    ];

    _handleClick() {
        this.dispatchEvent(
            new CustomEvent('pli-dialog-close', {
                composed: true,
                bubbles: true,
            }),
        );
    }

    render() {
        return html`<pli-button width="full" size="lg" variant="${this.variant}" .onClick="${() => this._handleClick()}"
            ><slot></slot
        ></pli-button>`;
    }
}

@customElement('pli-dialog-open-button')
class PliDialogOpenButton extends LitElement {
    @property()
    variant?: PliButtonProps['variant'] = 'primary';

    @property()
    size?: PliButtonProps['size'] = 'lg';

    @property()
    width?: PliButtonProps['width'] = 'full';

    @property()
    iconStart?: PliButtonProps['iconStart'];

    @property()
    iconEnd?: PliButtonProps['iconEnd'];

    @property({ type: Number })
    iconSize?: PliButtonProps['iconSize'];

    @property({ type: Boolean })
    disabled: PliButtonProps['disabled'] = false;

    static styles = [
        css`
            :host {
                width: 100%;
            }
        `,
    ];

    _handleClick() {
        this.dispatchEvent(
            new CustomEvent('pli-dialog-open', {
                composed: true,
                bubbles: true,
            }),
        );
    }

    render() {
        return html`<pli-button
            width="${this.width}"
            size="${this.size}"
            variant="${this.variant}"
            .iconStart="${this.iconStart}"
            .iconEnd="${this.iconEnd}"
            .iconSize="${this.iconSize}"
            .onClick="${() => this._handleClick()}"
            ?disabled="${this.disabled}"
        >
            <slot name="icon-left" slot="icon-left"></slot>
            <slot></slot
        ></pli-button>`;
    }
}

@customElement('pli-dialog-primary-button')
class PliDialogPrimaryButton extends LitElement {
    @property()
    variant?: PliButtonProps['variant'] = 'primary';

    @property({ type: Boolean })
    disabled: PliButtonProps['disabled'] = false;

    static styles = [
        css`
            :host {
                width: 100%;
            }
        `,
    ];

    _handleClick() {
        this.dispatchEvent(
            new CustomEvent('pli-dialog-primary-dismiss', {
                composed: true,
                bubbles: true,
            }),
        );
    }

    render() {
        return html`<pli-button
            ?disabled="${this.disabled}"
            width="full"
            size="lg"
            variant="${this.variant}"
            .onClick="${() => this._handleClick()}"
            ><slot></slot
        ></pli-button>`;
    }
}
