import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';
import { noAlertsImage, noCasesImage, noReportsImage } from './assets/illustrations';

export const availableIllustrations = ['no-alerts', 'no-cases', 'no-reports'] as const;

type PliIllustrationVariant = (typeof availableIllustrations)[number];

const illustrationMap: Record<PliIllustrationVariant, string> = {
    'no-alerts': noAlertsImage,
    'no-cases': noCasesImage,
    'no-reports': noReportsImage,
};

export interface PliIllustrationProps {
    variant: PliIllustrationVariant;
}

@customElement('pli-illustration')
class PliIllustration extends LitElement implements PliIllustrationProps {
    @property()
    variant: PliIllustrationVariant = 'no-alerts';

    static styles = [
        baseStyles,
        css`
            .pli-illustration {
                display: grid;
                position: relative;
                aspect-ratio: 1 / 1;
                place-content: center;
            }

            img {
                display: block;
                width: 100%;
            }
        `,
    ];

    render() {
        return html`
            <div class="pli-illustration">
                <img src="${illustrationMap[this.variant]}" />
            </div>
        `;
    }
}
