import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';

import './pli-text';

export interface PliTabNavigationProps {
    items: string[];
}

@customElement('pli-tab-navigation')
class PliTabNavigation extends LitElement {
    @property({ type: Array })
    items: string[];

    @property()
    currentItem: string;

    _initialActiveListElement: HTMLElement | null = null;

    _clickHandler(event: PointerEvent, index: number) {
        const target = event.currentTarget as HTMLElement;
        if (!target) return;

        this._initialActiveListElement?.classList.remove('initial-active');
        this._initialActiveListElement = null;

        this.setBorderSize(target);
        const ev: PliTabNavigationEvent = new CustomEvent('navigate', {
            detail: {
                current: this.items[index],
            },
        });

        this.dispatchEvent(ev);
    }

    private setBorderSize(target: HTMLElement) {
        const { left, width } = target.getBoundingClientRect();
        const { left: parentLeft } = this.shadowRoot.host.getBoundingClientRect();
        this.style.setProperty('--width', `${width}px`);
        this.style.setProperty('--left', `${left - parentLeft}px`);
    }

    async firstUpdated() {
        await this.updateComplete;
        const currentItemIndex = Boolean(this.currentItem) ? this.items.indexOf(this.currentItem) : 0;
        const listElements = Array.from(this.shadowRoot.querySelectorAll('li'));
        const listEl = listElements[currentItemIndex];

        this._initialActiveListElement = listEl;
        listEl.classList.add('initial-active');
    }

    static styles = [
        baseStyles,
        css`
            :host {
                --width: 0;
                --left: 0;
                --border-height: var(--size-0-25);
                --border-color: var(--color-border);
            }
            ul {
                display: flex;
                border-bottom: 1px solid var(--border-color);
            }

            li {
                display: block;
                list-style-type: none;
                padding-bottom: var(--border-height);
            }

            .wrapper {
                position: relative;
                overflow: hidden;
            }

            .initial-active {
                position: relative;
            }

            .initial-active:after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: var(--border-height);
                content: '';
                display: block;
                background-color: var(--color-mauve);
            }

            .border {
                --ease-curve: cubic-bezier(0.17, 0.67, 0.31, 1);
                --duration: 300ms;
                width: var(--width);
                left: var(--left);
                bottom: 0;
                position: absolute;
                height: var(--border-height);
                background-color: var(--color-mauve);
                transition:
                    width var(--duration) var(--ease-curve),
                    left var(--duration) var(--ease-curve);
            }

            button {
                padding: 0.5rem 1rem;
                background: none;
                border: 0;
            }
        `,
    ];

    borderTemplate() {
        return html` <div class="border"></div> `;
    }

    render() {
        return html`
            <div class="wrapper">
                <ul>
                    ${this.items.map(
                        (item, index) => html`
                            <li>
                                <button @click="${(e) => this._clickHandler(e, index)}">
                                    <pli-text variant="body-large">${item}</pli-text>
                                </button>
                            </li>
                        `,
                    )}
                </ul>
                <div class="border"></div>
            </div>
        `;
    }
}

export type PliTabNavigationEvent = CustomEvent<{ current: string }>;
