import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { PliIconProps } from './pli-icon';
import { styles } from './styles';
import './pli-text';

@customElement('pli-disclosure')
class PliDisclosure extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            summary::marker,
            summary::-webkit-details-marker {
                content: none;
                display: none;
            }
            summary::before,
            summary::after {
                content: '';
            }

            details,
            summary {
                appearance: none;
            }
        `,
    ];

    @property({ type: Boolean })
    open?: boolean;

    @property()
    placeholder: string;

    @state()
    _open = false;

    onToggle = () => {
        this._open = !this._open;
    };

    connectedCallback(): void {
        super.connectedCallback();
        this._open = this.open;
    }

    render() {
        const { onToggle, _open, placeholder } = this;
        const iconName: PliIconProps['name'] = _open ? 'chevron-up' : 'chevron-down';
        return html`<details @toggle="${onToggle}" open>
            <summary>
                <div class="flex items-center gap-1">
                    <pli-text as="p" variant="h3">${placeholder}</pli-text
                    ><pli-icon size="24" name="${iconName}"></pli-icon>
                </div>
            </summary>
            <slot></slot>
        </details>`;
    }
}
