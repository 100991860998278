import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import './pli-text';
import { baseStyles, statusStyles } from '.';
import { classMap } from 'lit/directives/class-map.js';
import { availableStatusKeys, availableStatuses, getClassNameForStatus, getStatus } from './utils/available-statuses';

export type PliDotVariant = (typeof availableStatusKeys)[number];
export interface PliDotLabelprops {
    variant?: PliDotVariant;
}

@customElement('pli-dot-label')
class PliDotLabel extends LitElement implements PliDotLabelprops {
    @property()
    variant?: PliDotVariant = 'New';

    static styles = [
        baseStyles,
        statusStyles,
        css`
            :host {
                --dot-size: var(--size-1);
            }
            .flex {
                display: flex;
                align-items: center;
                gap: var(--size-1);
            }

            .value {
                margin-left: auto;
            }
            .dot {
                width: var(--dot-size);
                height: var(--dot-size);
                border-radius: var(--dot-size);
            }

            pli-text:first-of-type {
                flex: 1;
            }
        `,
    ];

    render() {
        const variant = this.variant ?? 'New';
        const { className, statusText } = getStatus(variant);
        const dotClasses = {
            dot: true,
            [className]: true,
        };

        return html`
            <div class="flex">
                <div class="${classMap(dotClasses)}"></div>
                <pli-text variant="h6">${statusText}</pli-text>
            </div>
        `;
    }
}
