import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './pli-icon';
import { PliInputState } from './types/input-state';

export type Option = {
    value: string;
    label: string;
};

type OptGroup = {
    label: string;
    options: Option[];
};
export const availableInputSizes = ['sm', 'md'] as const;
type PliSelectSize = (typeof availableInputSizes)[number];

export interface PliSelectProps {
    options?: Option[];
    optGroups?: OptGroup[];
    placeholder: string;
    value?: string;
    size?: PliSelectSize;
    state?: PliInputState;
}

@customElement('pli-select')
class SingleSelect extends LitElement implements PliSelectProps {
    static styles = [
        css`
            :host {
                --border: var(--border-default);
                --focus-box-shadow: var(--input-border-focus-shadow);
                --padding-sm: var(--size-0-5) var(--size-1);
                --padding-md: var(--size-1) var(--size-1);
            }
            .select {
                position: relative;
            }

            select:focus {
                box-shadow: var(--focus-box-shadow);
                outline: 0;
            }

            select {
                appearance: none;
                padding: var(--padding);
                padding-right: var(--size-2-5);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
                border: var(--border);
                width: 100%;
                line-height: 1.4;
            }

            .icon-wrapper {
                position: absolute;
                height: 100%;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                padding: 0 var(--size-1);
                pointer-events: none;
            }
        `,
    ];

    @property({ type: Array })
    options?: Option[];

    @property({ type: Array })
    optGroups?: OptGroup[];

    @property()
    placeholder: string;

    @property()
    value: string;

    @property()
    state?: PliInputState = 'initial';

    @property()
    size: PliSelectSize;

    _emit = (event: Event) => {
        const value = (event.currentTarget as HTMLSelectElement).value;

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    };

    _renderSingle = () =>
        html`<select @change="${this._emit}">
            <option value="" disabled selected>${this.placeholder}</option>
            ${this.options.map(
                (option) => html`
                    <option value="${option.value}" .selected="${this.value === option.value}">${option.label}</option>
                `,
            )}
        </select>`;

    _renderOptGroups = () =>
        html`<select @change="${this._emit}">
            <option value="" disabled selected>${this.placeholder}</option>
            ${this.optGroups.map(
                (group) => html`
                    <optgroup label="${group.label}">
                        ${group.options.map(
                            (option) => html`
                                <option value="${option.value}" .selected="${this.value === option.value}">
                                    ${option.label}
                                </option>
                            `,
                        )}
                    </optgroup>
                `,
            )}
        </select>`;

    _setSizeStyle = () => {
        const { size } = this;

        this.style.setProperty('--padding', `var(--padding-${size ?? 'sm'})`);
    };

    _createStyleFromState = () => {
        this.style.setProperty('--border', `var(--border-${this.state})`);
        this.style.setProperty('--focus-box-shadow', `var(--input-border-focus-shadow-${this.state})`);
    };

    get hasOptGroups() {
        return Boolean(this.optGroups);
    }

    render() {
        const { _renderOptGroups, _renderSingle, _createStyleFromState, _setSizeStyle, hasOptGroups } = this;

        _setSizeStyle();
        _createStyleFromState();

        return html`<div class="select">
            <div class="icon-wrapper"><pli-icon name="chevron-down"></pli-icon></div>
            ${hasOptGroups ? _renderOptGroups() : _renderSingle()}
        </div>`;
    }
}
