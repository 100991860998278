import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './pli-text';
import { PliIconName } from './pli-icon';
import { when } from 'lit/directives/when.js';

export const availableColors = ['purple', 'gray'] as const;
type PliBadgeColor = (typeof availableColors)[number];

export interface PliBadgeProps {
    color?: PliBadgeColor;
    text: string;
    icon?: PliIconName;
}
@customElement('pli-badge')
class PliBadge extends LitElement {
    @property()
    color?: PliBadgeColor = 'purple';

    @property()
    text: string;

    @property()
    icon?: PliIconName;

    static styles = [
        css`
            .pli-badge {
                padding: var(--size-0-25) var(--size-0-5);
                border-radius: var(--radius-md);
                display: inline-flex;
                align-items: center;
                line-height: 1;
                gap: var(--size-0-5);
            }
            .pli-badge-purple {
                background-color: #ddd6fe;
                color: #020617;
            }
            .pli-badge-gray {
                background-color: #e2e8f0;
                color: #020617;
            }
        `,
    ];

    get cssColorName(): string {
        return `pli-badge-${this.color}`;
    }

    render() {
        const { cssColorName, text, icon } = this;
        const classes = classMap({
            'pli-badge': true,
            [cssColorName]: true,
        });
        return html`<span class="${classes}">
            ${when(icon, () => html`<pli-icon name="${icon}"></pli-icon>`)}
            <pli-text variant="caption">${text}</pli-text></span
        > `;
    }
}
