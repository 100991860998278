import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
    PliRoundedElementProps,
    PliRoundedElementRadius,
    createRoundedElementClassnames,
} from './utils/create-rounded-element-classnames';
import { styles } from './styles';
import { percentageFormatter } from '../utils/percentage-formatter';

export const availableSizes = ['xs', 'sm', 'md'] as const;
type PliProgressBarSize = (typeof availableSizes)[number];

export type PliProgressBarProps = PliRoundedElementProps<{
    value: number;
    size?: PliProgressBarSize;
    showPercentage?: boolean;
}>;

@customElement('pli-progress-bar')
class PliProgressBar extends LitElement implements PliProgressBarProps {
    @property({ type: Number })
    value: number;

    @property()
    size?: PliProgressBarSize = 'xs';

    @property()
    radius?: PliRoundedElementRadius = 0;

    @property({ type: Boolean })
    showPercentage?: boolean;

    static styles = [
        styles.rounded,
        css`
            :host {
                display: flex;
                --width: 0;
                --size-xs: var(--size-0-125);
                --size-sm: var(--size-1);
                --size-md: var(--size-1-5);
            }

            .relative {
                position: relative;
                width: 100%;
            }

            .percentage {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: var(--width);
                text-align: center;
                color: var(--color-white);
                z-index: var(--z-10);
                /* Check on design system forum about this size... */
                font-size: 10px;
                transition: width 0.5s;
            }

            progress[value] {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border: none;
                width: 100%;
                height: var(--size);
                background-color: var(--color-border);
                overflow: hidden;
                transition: width 0.5s;
                display: block;
            }

            progress[value]::-webkit-progress-bar {
                background-color: var(--color-border);
            }
            progress[value]::-webkit-progress-value {
                transition: width 0.5s;
                background-color: var(--button-background-primary);
            }

            progress[value]::-moz-progress-bar {
                background-color: var(--button-background-primary);
            }
        `,
    ];
    render() {
        const { radius, showPercentage } = this;
        const { classNames } = createRoundedElementClassnames({
            radius,
        });
        const valuePercentage = percentageFormatter(this.value);
        this.style.setProperty('--size', `var(--size-${this.size})`);
        this.style.setProperty('--width', valuePercentage);
        return html`<div class="relative">
            <progress class="${classNames}" max="100" value="${this.value}"></progress>
            ${showPercentage ? html`<div class="percentage">${valuePercentage}</div>` : null}
        </div>`;
    }
}
