import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { baseStyles, gridStyles } from 'pli';

@customElement('pli-grid')
class PliGrid extends LitElement {
    static styles = [baseStyles, gridStyles];

    render() {
        return html`
            <div class="grid gap-1">
                <slot></slot>
            </div>
        `;
    }
}
