import { LitElement, css, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import './pli-icon';
import './pli-card';
import { baseStyles, gridStyles } from '.';

type PliEnumFilterItem = {
    label: string;
    value: unknown;
};

export interface PliEnumFilterProps {
    placeholder: string;
    items: PliEnumFilterItem[];
}

@customElement('pli-enum-filter')
export class PliEnumFilter extends LitElement implements PliEnumFilterProps {
    @property({ type: Array })
    items: PliEnumFilterProps['items'] = [];

    @property()
    placeholder: string = 'Placeholder';

    @state()
    _open = false;

    @query('details')
    _details: HTMLDetailsElement;

    connectedCallback(): void {
        super.connectedCallback();
        document.addEventListener('click', this.clickHandler);
        document.addEventListener('keyup', this.keyupHandler);
        this._checkedItems = this.items.map((item) => item.value);
    }

    disconnectedCallback(): void {
        document.removeEventListener('click', this.clickHandler);
        document.removeEventListener('keyup', this.keyupHandler);
        super.disconnectedCallback();
    }

    keyupHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.closeDetails();
            this._details.querySelector('summary').focus();
        }
    };

    closeDetails() {
        this._details.open = false;
    }

    clickHandler = (event: MouseEvent) => {
        const clickedOutside = !event.composedPath().includes(this._details);
        if (clickedOutside) {
            this.closeDetails();
        }
    };

    static styles = [
        baseStyles,
        gridStyles,
        css`
            details {
                position: relative;
            }
            summary::marker,
            summary::-webkit-details-marker {
                content: none;
                display: none;
            }
            summary::before,
            summary::after {
                content: '';
            }
            summary {
                appearance: none;
                border: var(--border-default);
                border-radius: var(--radius-sm);
                padding: var(--size-0-5);
                display: flex;
                align-items: center;
            }
            .icon-wrapper {
                display: inline-flex;
                transition: transform 250ms ease;
                margin-left: auto;
            }
            .open {
                transform: rotate(180deg);
            }

            .body {
                position: absolute;
                width: 100%;
                top: calc(100% + var(--size-1));
                left: 0;
            }

            button {
                appearance: none;
                border: 0;
                border-top: var(--border-default);
                width: 100%;
                background: none;
                padding: var(--size-1);
            }

            pli-card > *:first-child {
                padding: var(--size-1);
            }

            label {
                position: relative;
                display: flex;
                align-items: center;
                flex: 1;
                gap: var(--size-0-5);
                padding: var(--size-0-25) 0;
            }

            input {
                opacity: 0;
                position: absolute;
            }

            input:focus + label pli-icon {
                outline: var(--border-0-25);
                border-radius: var(--radius-sm);
            }
        `,
    ];

    onToggle(open: boolean) {
        this._open = open;
    }

    @state()
    _checkedItems = [];

    dispatchChanged() {
        const selectedItems = this.items.filter((item) => this._checkedItems.includes(item.value));

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value: selectedItems,
                },
            }),
        );
    }

    onChange = (item: PliEnumFilterItem, event: InputEvent) => {
        const target = event.target as HTMLInputElement;
        this._checkedItems = target.checked
            ? [...this._checkedItems, item.value]
            : this._checkedItems.filter((i) => i !== item.value);

        this.dispatchChanged();
    };

    async _reset() {
        this._checkedItems = this.items.map((v) => v.value);
        this.dispatchChanged();
    }

    render() {
        const icon = (open: boolean) =>
            html`<div class="icon-wrapper ${open ? 'open' : 'closed'}"><pli-icon name="chevron-down"></pli-icon></div>`;

        const checkboxItem = (item: PliEnumFilterItem) => {
            const isChecked = this._checkedItems.includes(item.value);
            const iconDisplayed = isChecked ? 'check-square-fill' : 'square';

            return html` <input
                    type="checkbox"
                    .checked="${isChecked}"
                    id="${item.label}"
                    @change="${(event: InputEvent) => this.onChange(item, event)}"
                />
                <label for="${item.label}">
                    <pli-icon name="${iconDisplayed}"></pli-icon>
                    ${item.label}</label
                >`;
        };

        return html`
            <details @toggle="${() => this.onToggle(!this._open)}">
                <summary>${this.placeholder} ${icon(this._open)}</summary>
                <div class="body">
                    <pli-card padding="collapsed" borderType="default">
                        <div class="grid-vertical">${this.items.map((item) => checkboxItem(item))}</div>
                        <button @click="${this._reset}">Reset</button>
                    </pli-card>
                </div>
            </details>
        `;
    }
}
