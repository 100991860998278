import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles } from '.';
import { PliElementWidth } from './types/element-width';
import { PliElementPadding } from './types/element-padding';

export const availableCardBorders = ['default', 'none'] as const;
type PliCardBorder = (typeof availableCardBorders)[number];

export const availableCardBackgrounds = ['fill', 'none'] as const;
type PliCardBackground = (typeof availableCardBackgrounds)[number];

export const availableCardBoxShadows = ['default', 'none'] as const;
type PliCardBoxShadow = (typeof availableCardBoxShadows)[number];

export interface PliCardProps {
    padding: PliElementPadding;
    borderType: PliCardBorder;
    background?: PliCardBackground;
    boxShadow?: PliCardBoxShadow;
    width?: PliElementWidth;
}

@customElement('pli-card')
class PliCard extends LitElement implements PliCardProps {
    @property()
    padding: PliElementPadding = 'default';

    @property()
    borderType: PliCardBorder = 'none';

    @property()
    background: PliCardBackground = 'fill';

    @property()
    boxShadow: PliCardBoxShadow = 'default';

    @property()
    width: PliElementWidth = 'full';

    static styles = [
        baseStyles,
        css`
            :host {
                --border-none: 0;

                --background-fill: var(--color-white);
                --background-none: none;

                --box-shadow-default: rgba(0, 0, 0, 0.08) 0px 2px 3px;
                --box-shadow-none: none;

                --negative-margin: calc(var(--padding) * -1);

                --padding: var(--padding-default);
                --padding-collapsed: 0;
                --padding-default: var(--size-1-5);

                --display-full: grid;
                --display-hug-content: inline-flex;
            }
            .pli-card {
                border-radius: 0.5rem;
                box-shadow: var(--box-shadow);
                background-color: var(--background);
                padding: var(--padding);
                height: 100%;
                border: var(--border);
                display: var(--display);
            }
        `,
    ];
    render() {
        this.style.setProperty('--padding', `var(--padding-${this.padding})`);
        this.style.setProperty('--border', `var(--border-${this.borderType})`);
        this.style.setProperty('--background', `var(--background-${this.background})`);
        this.style.setProperty('--box-shadow', `var(--box-shadow-${this.boxShadow})`);
        this.style.setProperty('--display', `var(--display-${this.width})`);

        return html`
            <div class="pli-card">
                <slot></slot>
            </div>
        `;
    }
}
