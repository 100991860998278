import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { userBubbleNameFormatter } from '../utils/name-formatter';
import ColorHash from 'color-hash';
import './pli-text';

const mixColor = function (color_1: string, color_2: string, weight: number) {
    color_1 = color_1.replace(/#/g, '');
    color_2 = color_2.replace(/#/g, '');

    function d2h(d) {
        return d.toString(16);
    } // convert a decimal value to hex

    function h2d(h) {
        return parseInt(h, 16);
    } // convert a hex value to decimal

    weight = typeof weight !== 'undefined' ? weight : 50; // set the weight to 50%, if that argument is omitted

    var color = '#';

    for (var i = 0; i <= 5; i += 2) {
        // loop through each of the 3 hex pairs—red, green, and blue
        var v1 = h2d(color_1.substr(i, 2)), // extract the current pairs
            v2 = h2d(color_2.substr(i, 2)),
            // combine the current pairs from each source color, according to the specified weight
            val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

        while (val.length < 2) {
            val = '0' + val;
        } // prepend a '0' if val results in a single digit

        color += val; // concatenate val to our new color string
    }

    return color; // PROFIT!
};

export interface PliUserBubbleProps {
    firstName: string;
    lastName: string;
    userId: string;
}

@customElement('pli-user-bubble')
class PliUserBubble extends LitElement {
    @property()
    firstName: string;
    @property()
    lastName: string;
    @property()
    userId: string;

    static styles = [
        css`
            :host {
                --background-color: #e9ecef;
                --foreground-color: #495057;
            }
            .pli-user-bubble {
                --size: var(--size-2-5);
                display: grid;
                place-content: center;
                width: var(--size);
                height: var(--size);
                border-radius: var(--radius-full);
                background-color: var(--background-color);
                color: var(--foreground-color);
                font-weight: 700;
            }
        `,
    ];

    colorHash: ColorHash;

    createBackgroundColor(userId: string) {
        let hex = this.colorHash.hex(userId);

        return hex;
    }

    createForegroundColor(userId: string) {
        const bgColor = this.colorHash.hex(userId);
        return mixColor('000000', bgColor, 40);
    }

    connectedCallback(): void {
        super.connectedCallback();
        this.colorHash = new ColorHash({ saturation: 0.98, lightness: 0.81 });
    }

    setStyles() {
        const bgColor = this.createBackgroundColor(this.userId);
        const fgColor = this.createForegroundColor(this.userId);

        this.style.setProperty('--background-color', bgColor);
        this.style.setProperty('--foreground-color', fgColor);
    }

    get isUnassigned() {
        const { firstName, lastName, userId } = this;
        return [firstName, lastName, userId].every((i) => !Boolean(i));
    }

    _baseLayout(name: string) {
        return html` <div class="pli-user-bubble">${name}</div>`;
    }

    render() {
        if (this.isUnassigned) {
            return this._baseLayout('U/A');
        }

        const { firstName, lastName, userId } = this;
        const nameFormatted = userBubbleNameFormatter({ firstName: firstName, lastName: lastName });
        this.setStyles();

        return this._baseLayout(nameFormatted);
    }
}
