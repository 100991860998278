import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { baseStyles, statusStyles } from '.';
import { classMap } from 'lit/directives/class-map.js';

import './pli-text';
import { availableStatusKeys, availableStatuses, getStatus } from './utils/available-statuses';

export type PliStatusLabelVariant = (typeof availableStatusKeys)[number];
export interface PliStatusLabelProps {
    variant: PliStatusLabelVariant;
}
@customElement('pli-status-label')
class PliStatusLabel extends LitElement {
    @property()
    variant: PliStatusLabelVariant = 'New';

    _statuses = availableStatuses;

    static styles = [
        baseStyles,
        statusStyles,
        css`
            .pli-status-label {
                padding: var(--size-0-25) var(--size-0-5);
                border-radius: var(--radius-md);
                display: inline-flex;
                align-items: center;
                line-height: 1;
            }
        `,
    ];

    render() {
        const { variant } = this;
        const { className, statusText } = getStatus(variant);
        const classes = classMap({
            'pli-status-label': true,
            [className]: true,
        });
        return html`<span class="${classes}"><pli-text variant="caption">${statusText}</pli-text> </span> `;
    }
}
