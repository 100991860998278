import { Chart, DoughnutController, ArcElement } from 'chart.js';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { baseStyles } from '.';

export interface PliDonutChartProps {
    labels: string[];
    data: number[];
    colors: string[];
}

@customElement('pli-donut-chart')
class PliDonutChart extends LitElement implements PliDonutChartProps {
    @property({ type: Array })
    labels: string[];

    @property({ type: Array })
    data: number[];

    @property({ type: Array })
    colors: string[];

    canvasRef: Ref<HTMLCanvasElement> = createRef();

    static styles = [
        baseStyles,
        css`
            .canvas-wrapper {
                position: relative;
                display: grid;
                aspect-ratio: 1/1;
                place-content: center;
            }

            canvas {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        `,
    ];

    connectedCallback(): void {
        super.connectedCallback();
        Chart.register(DoughnutController);
        Chart.register(ArcElement);
    }

    protected firstUpdated(): void {
        new Chart(this.canvasRef.value, {
            type: 'doughnut',
            data: {
                labels: this.labels,
                datasets: [
                    {
                        data: this.data,
                        backgroundColor: this.colors,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: 90,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    }

    render() {
        return html`
            <div class="canvas-wrapper">
                <canvas class="donut-canvas" ${ref(this.canvasRef)}></canvas>
                <slot></slot>
            </div>
        `;
    }
}
